import Alpine from "alpinejs"

// This is for showing the stores inside the console only in dev mode
const alpineStores = {
  products: Alpine.store("products"),
  cart: Alpine.store("cart"),
  utils: Alpine.store("utils"),
  toast: Alpine.store("toast"),
  // aLaCart: Alpine.store('aLaCart'),
  productUtils: Alpine.store("productUtils"),
  discounts: Alpine.store("discounts"),
}
window.alpineStores = alpineStores
// console.log('stores:', window.alpineStores)
// make a cool looking console log that points to the window.alpineStores
if (import.meta.env.DEV) {
  console.log(
    "%c🏔️ Alpine Stores Data 🛍️",
    "background: #333; color: #fff; font-size: 10px; padding: 4px;",
    alpineStores
  )
}

  Alpine.start();